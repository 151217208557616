<template>
  <div class="contain">
    <div class="contain-top" @click="back">
      <img src="@/assets/icon_left.png" alt="" />
      <span>返回答题列表</span>
    </div>
    <div
      class="big"
      v-if="details ? details.type == 1 : ''"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div class="contain-center">
        <div class="contain-center-top">
          单选题<span>（{{ index + 1 }}/{{ total }}）</span>
        </div>
        <div class="contain-center-title" v-html="list[index].title"></div>
        <div class="contain-center-check">
          <div class="contain-center-check-left">
            <div
              class="contain-center-check-box"
              v-for="(item, indexs) in details.option"
              :key="indexs"
              @click="check(indexs)"
            >
              {{ String.fromCharCode(item.name + indexs) }}&nbsp;&nbsp;
              <img v-if="active == indexs" src="@/assets/checked.png" alt="" />
              <img v-else src="@/assets/no_checked.png" alt="" />
              <span>{{ item.content }}</span>
            </div>
          </div>
          <img class="img" v-if="isok == 0" src="@/assets/success.png" alt="" />
          <img
            class="img"
            v-else-if="isok == 1"
            src="@/assets/error.png"
            alt=""
          />
          <span v-else></span>
        </div>
        <div class="contain-center-btn">
          <el-row>
            <el-button type="primary" plain @click="go(0)">上一题</el-button>
            <el-button type="primary" @click="submit(1)" style="margin: 0 60px">
              {{ is_repeat ? "提交" : "重做" }}
            </el-button>
            <el-button type="primary" plain @click="go(1)">下一题</el-button>
          </el-row>
        </div>
      </div>
      <div class="contain-bottom" v-if="show">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">
          正确答案：<span>{{ String.fromCharCode(details.answer) }}</span>
        </div>
        <div class="contain-bottom-msg">{{ details.analysis }}</div>
      </div>
    </div>
    <div
      class="big"
      v-else-if="details ? details.type == 2 : ''"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div class="contain-center">
        <div class="contain-center-top">
          多选题<span>（{{ index + 1 }}/{{ total }}）</span>
        </div>
        <div class="contain-center-title" v-html="list[index].title"></div>
        <div class="contain-center-check">
          <div class="contain-center-check-left">
            <div
              class="contain-center-check-box"
              v-for="(item, indexs) in details.option"
              :key="indexs"
              @click="checkAll(indexs)"
            >
              {{ String.fromCharCode(item.name + indexs) }}&nbsp;&nbsp;

              <img v-if="item.isChat" src="@/assets/checked.png" alt="" />
              <img v-else src="@/assets/no_checked.png" alt="" />
              <span>{{ item.content }}</span>
            </div>
          </div>
          <img class="img" v-if="isok == 0" src="@/assets/success.png" alt="" />
          <img
            class="img"
            v-else-if="isok == 1"
            src="@/assets/error.png"
            alt=""
          />
          <span v-else></span>
        </div>
        <div class="contain-center-btn">
          <el-row>
            <el-button type="primary" plain @click="go(0)">上一题</el-button>
            <el-button
              type="primary"
              @click="submit(2)"
              style="margin: 0 60px"
              >{{ is_repeat ? "提交" : "重做" }}</el-button
            >
            <el-button type="primary" plain @click="go(1)">下一题</el-button>
          </el-row>
        </div>
      </div>
      <div class="contain-bottom" v-if="show">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">
          正确答案：<span v-for="item in details.answerList" :key="item">
            {{ String.fromCharCode(item) }},
          </span>
        </div>
        <div class="contain-bottom-msg">{{ details.analysis }}</div>
      </div>
    </div>
    <div
      class="big"
      v-else-if="details ? details.type == 3 : ''"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div class="contain-center">
        <div class="contain-center-top">
          填空题 <span>（{{ index + 1 }}/{{ total }}）</span>
        </div>
        <div class="contain-center-title" v-html="details.title"></div>
        <div class="contain-center-content">
          <div
            class="contain-center-content-box"
            v-for="(v, i) in value"
            :key="i"
          >
            <span>答案{{ i + 1 }}：</span
            ><el-input
              v-model="v.val"
              clearable
              style="width: 200px"
              placeholder="请输入答案"
            ></el-input>
          </div>
          <!-- <img class="img" v-if="isok==0" src="@/assets/success.png" alt="">
                    <img class="img" v-else-if="isok==1" src="@/assets/error.png" alt="">
                    <span v-else></span> -->
        </div>
        <div class="contain-center-btn">
          <el-row>
            <el-button type="primary" plain @click="go(0)">上一题</el-button>
            <el-button
              type="primary"
              @click="submit(3)"
              style="margin: 0 60px"
              >{{ is_repeat ? "提交" : "重做" }}</el-button
            >
            <el-button type="primary" plain @click="go(1)">下一题</el-button>
          </el-row>
        </div>
      </div>
      <div class="contain-bottom" v-if="show">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">
          正确答案：<span>{{ details.answer }}</span>
        </div>
        <div class="contain-bottom-msg">{{ details.analysis }}</div>
      </div>
    </div>
    <div
      class="big"
      v-else-if="details ? details.type == 4 : ''"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div class="contain-center">
        <div class="contain-center-top">
          简答题<span>（{{ index + 1 }}/{{ total }}）</span>
        </div>
        <div class="contain-center-title" v-html="details.title"></div>
        <div class="textarea">
          <el-input
            style="border: none"
            type="textarea"
            :rows="5"
            placeholder="在此写下你的解答。"
            v-model="answer.answer"
          >
          </el-input>
        </div>
        <div class="contain-center-btn">
          <el-row>
            <el-button type="primary" plain @click="go(0)">上一题</el-button>
            <el-button
              type="primary"
              @click="submit(4)"
              style="margin: 0 60px"
              >{{ is_repeat ? "提交" : "重做" }}</el-button
            >
            <el-button type="primary" plain @click="go(1)">下一题</el-button>
          </el-row>
        </div>
      </div>
      <!-- <input type="text" v-model="value.val1"> -->
      <div class="contain-bottom" v-if="show">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">参考答案</div>
        <div class="contain-bottom-msg">{{ details.answer }}</div>
      </div>
    </div>
    <div
      class="big"
      v-if="details ? details.type == 5 : ''"
      v-loading="loading"
      element-loading-text="拼命加载中"
    >
      <div class="contain-center">
        <div class="contain-center-top">
          判断题<span>（{{ index + 1 }}/{{ total }}）</span>
        </div>
        <div class="contain-center-title" v-html="list[index].title"></div>
        <div class="contain-center-check">
          <div
            class="contain-center-check-left"
            style="display: flex; margin-top: 30px"
          >
            <div
              class="contain-center-checkbox"
              :class="judge == 1 ? 'contain-center-checkbox-check' : ''"
              style="margin-right: 20px"
              @click="checkJudge(1, '正确')"
            >
              <i class="el-icon-success" style="margin-right: 10px"></i> 正确
            </div>
            <div
              class="contain-center-checkbox"
              :class="judge == 2 ? 'contain-center-checkbox-check' : ''"
              @click="checkJudge(2, '错误')"
            >
              <i class="el-icon-error" style="margin-right: 10px"></i> 错误
            </div>
          </div>
          <img class="img" v-if="isok == 0" src="@/assets/success.png" alt="" />
          <img
            class="img"
            v-else-if="isok == 1"
            src="@/assets/error.png"
            alt=""
          />
          <span v-else></span>
        </div>
        <div class="contain-center-btn">
          <el-row>
            <el-button type="primary" plain @click="go(0)">上一题</el-button>
            <el-button
              type="primary"
              @click="submit(5)"
              style="margin: 0 60px"
              >{{ is_repeat ? "提交" : "重做" }}</el-button
            >
            <el-button type="primary" plain @click="go(1)">下一题</el-button>
          </el-row>
        </div>
      </div>
      <div class="contain-bottom" v-if="show">
        <div class="contain-bottom-top">答案解析</div>
        <div class="contain-bottom-success">
          正确答案：<span>{{ details.answer }}</span>
        </div>
        <div class="contain-bottom-msg">{{ details.analysis }}</div>
      </div>
    </div>
    <el-empty :image-size="200" v-if="!details"></el-empty>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      show: false,
      active: -1,
      textarea: "",
      type: 0,
      form: {
        teacher_question_bank_type_id: "",
        is_last_start: "",
        is_random: "",
        limit: 100,
      },
      index: 0,
      list: [],
      total: 0,
      details: null,
      answer: {
        teacher_question_bank_id: "",
        answer: "",
      },
      isok: 2,
      length: 0,
      value: [],
      btnTitle: "提交",
      loading: true,
      is_repeat: true,
      noData: false,
      judge: null,
    };
  },
  created() {
    this.form.teacher_question_bank_type_id =
      this.$route.query.teacher_question_bank_type_id;
    if (this.$route.query.is_random) {
      this.form.is_random = this.$route.query.is_random;
    }
    this.answer.teacher_question_bank_id =
      this.$route.query.teacher_question_bank_type_id;
    this.getSubjectList(0);
  },
  methods: {
    checkAll(index) {
      if (this.isok == 0) {
        return;
      } else if (this.isok == 1) {
        return;
      } else {
        this.details.option[index].isChat = !this.details.option[index].isChat;
        this.$forceUpdate(); //强制更新
      }
    },
    checkJudge(index, answer) {
      if (this.isok == 0) {
        return;
      } else if (this.isok == 1) {
        return;
      } else {
        this.judge = index;
        this.answer.answer = answer;
      }
    },
    check(index) {
      if (this.isok == 0) {
        return;
      } else if (this.isok == 1) {
        return;
      } else {
        this.active = index;
      }
    },
    submit(index) {
      if (this.isok == 2) {
        if (index == 1) {
          if (this.active == -1) {
            this.isok = 2;
            this.$message.error("答案不能为空！");
            return;
          }
          this.answer.answer =
            this.details.option[this.active].name + this.active;
          this.is_repeat = !this.is_repeat;
          if (
            this.details.option[this.active].name + this.active ==
            this.details.answer
          ) {
            this.isok = 0;
          } else {
            this.isok = 1;
          }
        } else if (index == 2) {
          // this.answer.answer
          let answer = "";
          this.details.option.forEach((item, index) => {
            if (!answer) {
              if (item.isChat) {
                answer = item.name + index;
              }
            } else {
              if (item.isChat) {
                answer = answer + "," + (item.name + index);
              }
            }
          });
          console.log(answer, this.details.answer);
          this.answer.answer = answer;
          this.is_repeat = !this.is_repeat;
          if (answer === this.details.answer) {
            this.isok = 0;
          } else {
            this.isok = 1;
          }
        } else if (index == 3) {
          let arr = [];
          this.value.forEach((item) => {
            arr.push(item.val);
          });
          this.answer.answer = arr.toString();
          this.is_repeat = !this.is_repeat;
          if (arr.toString() == this.details.answer) {
            this.isok = 0;
          } else {
            this.isok = 1;
          }
        } else {
          if (this.answer.answer == this.details.answer) {
            this.isok = 0;
          } else {
            this.isok = 1;
          }
          this.is_repeat = !this.is_repeat;
        }
        this.addAnswerLog();
      } else if (this.isok == 1) {
        this.btnTitle = "重做";
        this.answer.answer = "";
        this.active = -1;
        this.isok = 2;
        this.is_repeat = true;
        this.show = false;
        this.judge = null;
        if (index == 2) {
          this.details.option.forEach((item) => {
            item.isChat = false;
          });
        }
        if (index == 3) {
          this.value.forEach((item) => {
            item.val = "";
          });
        }
      } else {
        this.isok = 2;
        this.is_repeat = true;
        this.show = false;
        this.answer.answer = "";
        this.active = null;
        this.judge = null;
        if (index == 2) {
          this.details.option.forEach((item) => {
            item.isChat = false;
          });
        }
        if (index == 3) {
          this.value.forEach((item) => {
            item.val = "";
          });
        }
        return;
      }
    },
    back() {
      this.$router.push("/question/question");
    },
    go(type) {
      this.details.title = this.details.title.replaceAll("_____", "{input}");
      this.is_repeat = true;
      this.answer.answer = "";
      this.judge = null;
      if (this.details.type == 2) {
        this.details.option.forEach((option) => {
          option.isChat = false;
        });
      }
      if (type == 1) {
        if (!this.show) {
          this.$message.error("请先回答当前问题");
          return;
        }
        this.show = false;
        this.answer.answer = "";
        if (this.index + 1 == this.total) {
          this.index = this.total - 1;
          this.$message.error("已经是最后一题了！");
          if (this.isok == 0 || this.isok == 1) {
            this.show = true;
          }
          return;
        } else {
          if (this.index + 1 == this.form.limit) {
            this.value = [];
            this.form.limit += 100;
            console.log(this.form.limit, "");
            this.getSubjectList(this.index + 1);
          }
          this.isok = 2;
          this.answer.answer = "";
          this.index = this.index + 1;
        }
      } else {
        this.show = false;
        this.answer.answer = "";
        this.active = -1;
        this.isok = 2;
        this.is_repeat = true;
        this.judge = null;
        this.value.forEach((item) => {
          item.val = "";
        });
        if (this.index > 0) {
          this.isok = 2;
          this.answer.answer = "";
          this.index = this.index - 1;
        } else {
          this.index = 0;
          this.$message.error("已经是第一题了！");
          if (this.isok == 0 || this.isok == 1) {
            this.show = true;
          }
          return;
        }
      }
      this.getDetails(this.index);
    },
    getDetails(index) {
      this.value = [];
      this.answer.teacher_question_bank_id = this.list[index].id;
      this.details = this.list[index];
      if (this.list[index].type == 1) {
        this.details.option = JSON.parse(this.list[index].option);
      } else if (this.list[index].type == 2) {
        this.details.option = JSON.parse(this.list[index].option);
        this.details.option.forEach((item) => {
          item.isChat = false;
        });
        this.details.answerList = this.details.answer.split(",");
        console.log(this.details.answerList, "============多选题");
      } else if (this.list[index].type == 3) {
        this.length = this.details.title.split("{input}").length - 1;
        for (let index = 0; index < this.length; index++) {
          this.value.push({ val: "" });
        }
        this.details.title = this.details.title.replaceAll("{input}", "_____");
      }
    },
    getSubjectList(num) {
      this.loading = true;
      request({
        url: "/api/teacherend/teacherQuestionBank/list",
        method: "get",
        params: this.form,
      }).then((response) => {
        this.list = response.data.data;
        this.loading = false;
        this.getDetails(num);
        this.total = response.data.total;
      });
    },
    addAnswerLog() {
      this.show = false;
      if (!this.answer.answer) {
        this.isok = 2;
        this.$message.error("答案不能为空！");
        return;
      }
      request({
        url: "/api/teacherend/teacherQuestionBank/addAnswerLog",
        method: "post",
        params: this.answer,
      }).then((response) => {
        this.answer.answer = "";
        this.show = true;
      });
    },
  },
};
</script>
<style lang="scss">
@mixin flex($fd: row, $ai: stretch, $jc: flex-start, $fw: nowrap) {
  display: flex;
  flex-direction: $fd;
  align-items: $ai;
  justify-content: $jc;
  flex-wrap: $fw;
}
.contain {
  min-height: calc(100vh - 160px);
  background: #ffffff;
  border-radius: 9px;
  .contain-top {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding: ;
    padding-top: 20px;
    padding-left: 50px;
    img {
      width: 15px;
      height: 15px;
    }
    span {
      margin-left: 10px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #506176;
    }
  }
  .big {
    padding-left: 50px;
    .contain-center {
      margin-top: 46px;
      padding-bottom: 30px;
      border-bottom: 1px solid #f9fafd;
      .contain-center-top {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #506176;
        span {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #506176;
          margin-left: 20px;
        }
      }
      .contain-center-title {
        margin-top: 20px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 34px;
      }
      .contain-center-content {
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        .img {
          width: 120px;
          height: 102px;
          margin-left: 140px;
        }
        .contain-center-content-box {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
          margin-right: 20px;
          span {
            margin-right: 10px;
          }
        }
      }
      .textarea {
        margin-top: 36px;
        margin-bottom: 80px;
        position: relative;
        .el-textarea__inner {
          border: none;
        }
        .img-box {
          position: absolute;
          top: 0;
          left: 300px;
          .img {
            width: 120px;
            height: 102px;
            margin-left: 140px;
          }
        }
      }
      .contain-center-check {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .img {
          width: 120px;
          height: 102px;
          margin-left: 140px;
        }
        .contain-center-check-left {
          .contain-center-check-box {
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 25px 0;
            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }
            span {
              margin-left: 17px;
              font-size: 20px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }
          .contain-center-checkbox {
            width: 105px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #cccccc;
            border-radius: 8px;
            color: #999999;
            font-size: 20px;
            cursor: pointer;
          }
          .contain-center-checkbox-check {
            border-color: red;
            color: red;
          }
        }
      }
      .contain-center-btn {
        margin-top: 45px;
      }
    }
    .contain-bottom {
      margin-top: 20px;
      .contain-bottom-top {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #506176;
      }
      .contain-bottom-success {
        margin-top: 15px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        span {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #f24342;
        }
      }
      .contain-bottom-msg {
        margin-top: 12px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 31px;
      }
    }
  }
}
</style>